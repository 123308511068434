body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: rgb(220, 220, 220);
}

img {
  height: 100vh;
  /* height: 200px; */
  /* position: absolute; */
  /* transform: rotate(-90deg); */
  /* background: ; */
  /* position: absolute; */
  /* left: -300px; */
  /* top: 250px; */
}

.row2 {
  font-family: 'Yantramanav', sans-serif;
}

p {
  font-family: 'Yantramanav', sans-serif;
}

.col3 {
  text-align: left;
  font-family: 'Archivo Black', sans-serif;
  text-transform: uppercase;
}

.donate {
  /* transform: rotate(-90deg); */
  white-space: nowrap;
}
