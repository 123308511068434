.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 40s linear;
  }
}

.App-header {
  background-color: rgb(220, 220, 220);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
/* 
a:hover {
  color: white;
} */
a {
  color: black;
}

a:visited {
  color: black;
}

a:hover {
  color: rgb(232, 160, 174);
}

h2 {
  text-align: left;
  font-family: 'Archivo Black', sans-serif;
  text-transform: uppercase;
}

h3 {
  text-align: left;
}
h1 {
  font-family: 'Archivo Black', sans-serif;
  text-transform: uppercase;
}

video {
  border-radius: 16px;
}

.deepa {
  background-color: rgb(232, 160, 174);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
